import { useCallback, useState } from "react";

import { getAuthorizedAPIDataSharedArgs } from "../fetchers/getAuthorizedAPIData";
import getVoucherData, { getVoucherDataMainArgs, Voucher } from "../fetchers/getVoucherData";
import redeemVoucher, { redeemVoucherMainArgs } from "../fetchers/redeemVoucher";

export interface useVoucherDataArgs extends getAuthorizedAPIDataSharedArgs {
  
}

export type VoucherData = Record<string, Voucher | null>;
export type UseVoucherData = ReturnType<typeof useVoucherData>;
export type GetVoucherDataForTemplateIDs = (templateIDs: string | string[] | null | undefined) => Voucher[] | null;

export const getTemplateIDSignature = (templateIDs: string | string[] | null | undefined) => {
  if(templateIDs === null || typeof templateIDs === "undefined") {
    return "";
  }

  if(typeof templateIDs === "string") {
    templateIDs = templateIDs.split(",");
  }

  templateIDs = templateIDs.filter(templateID => templateID.trim());
  templateIDs.sort();
  templateIDs = templateIDs.filter((templateID, templateIDIndex) => !templateIDIndex || (templateIDs![templateIDIndex - 1] !== templateID));
  templateIDs = templateIDs.join(",");
  
  return templateIDs;
};

export default function useVoucherData({
  apiURL,
  authorizationToken,
}: useVoucherDataArgs) {
  const [data, setData] = useState<VoucherData>({});
  const [error, setError] = useState<unknown | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(false);

  const boundGetVoucherData = useCallback(async ({ voucherCode }: getVoucherDataMainArgs) => {
    let voucherData: Voucher | null = null;
  
    try {
      setIsLoading(true);
      setIsValidating(true);
      setError(null);

      voucherData = await getVoucherData({
        apiURL,
        authorizationToken,
        voucherCode,
      });
  
      if(voucherData) {
        setData((oldData) => ({
          ...oldData,
          [voucherCode]: voucherData,
        }));
      }
    } catch(error) {
      setError(error);
    } finally {
      setIsLoading(false);
      setIsValidating(false);
    }

    return voucherData;
  }, [
    apiURL,
    authorizationToken, 
  ]);

  const boundRedeemVoucher = useCallback(async ({
    orderNumber,
    visitDate,
    voucherCode
  }: redeemVoucherMainArgs) => {
    let voucherData: Voucher | null = null;
  
    try {
      setIsLoading(true);
      setIsValidating(true);
      setError(null);

      voucherData = await redeemVoucher({
        apiURL,
        authorizationToken,
        orderNumber,
        visitDate,
        voucherCode,
      });
  
      if(voucherData) {
        setData((oldData) => ({
          ...oldData,
          [voucherCode]: voucherData,
        }));
      }
    } catch(error) {
      setError(error);
    } finally {
      setIsLoading(false);
      setIsValidating(false);
    }

    return voucherData;
  }, [
    apiURL,
    authorizationToken
  ]);

  const getVoucherDataForTemplateIDs: GetVoucherDataForTemplateIDs = useCallback((templateIDs: string | string[] | null | undefined) => {
    if(!data) {
      return null;
    }

    if(!templateIDs) {
      return null;
    }
  
    if(typeof templateIDs === "string") {
      templateIDs = templateIDs.trim();
      templateIDs = templateIDs.split(",");
    }

    if(!templateIDs.length) {
      return null;
    }

    const filteredData = Object.values(data).filter(info => {
      for(const templateID of templateIDs) {
        if(!info?.template_ids.includes(templateID)) {
          return false;
        }
      }

      return true;
    }).filter(info => info !== null);

    if(!filteredData.length) {
      return null;
    }

    return filteredData as Voucher[];
  }, [data]);

  return {
    getVoucherData: boundGetVoucherData,
    getVoucherDataForTemplateIDs,
    redeemVoucher: boundRedeemVoucher,
    voucherData: data,
    voucherDataError: error,
    isVoucherDataLoading: isLoading,
    isVoucherDataValidating: isValidating,
  };
}