
import { goToNextStep } from "..";
import StepComponent, { Step, StepComponentProps, StepSubmitHandler } from "../Step";
import Steps from "../Steps";

import Input from "../../components/Input";
import TextContent from "../../components/TextContent";
import getLocalizedText from "../../formatters/getLocalizedText";


const onSubmit: StepSubmitHandler = async ({
  meta,
  helpers,
  values,
}) => {
  if(values.membershipTarget === "self") {
    await helpers.setFieldValue("firstName", values.primaryMemberFirstName);
    await helpers.setFieldValue("lastName", values.primaryMemberLastName);
    await helpers.setFieldValue("phoneNumber", values.primaryMemberPhoneNumber);
    await helpers.setFieldValue("email", values.primaryMemberEmail);
    await helpers.setFieldValue("streetAddress1", values.primaryMemberStreetAddress1);
    await helpers.setFieldValue("streetAddress2", values.primaryMemberStreetAddress2);
    await helpers.setFieldValue("city", values.primaryMemberCity);
    await helpers.setFieldValue("state", values.primaryMemberState);
    await helpers.setFieldValue("zip", values.primaryMemberZIP);
    await helpers.setFieldValue("country", values.primaryMemberCountry);
  }

  await goToNextStep(values.stepNumber, values.stepsEnabled, helpers.setFieldValue, helpers.setFieldTouched, meta);
};

const Component = (props: StepComponentProps) => {
  const {
    appConfiguration
  } = props;

  return (
    <StepComponent
      {...props}
      defaultHeaderLabel="Membership Information"
      headerLabelKey="membershipInformationHeader"
      showSteps={true}
      showMemberJoinButton={false}
    >
      <fieldset className="primaryAdultMemberInformationSection">
        <TextContent
          appConfiguration={appConfiguration}
          contentKey="primaryAdultMemberInformationSectionHeader"
          defaultValue="Primary Adult Member Information"
          tag="legend"
        />
        <Input
          label={getLocalizedText(appConfiguration, "firstNameLabel", "First Name")}
          name="primaryMemberFirstName"
        />
        <Input
          label={getLocalizedText(appConfiguration, "lastNameLabel", "Last Name")}
          name="primaryMemberLastName"
        />
        <Input
          label={getLocalizedText(appConfiguration, "phoneNumberLabel", "Phone Number")}
          name="primaryMemberPhoneNumber"
          type="tel"
        />
        <Input
          label={getLocalizedText(appConfiguration, "emailLabel", "Email")}
          name="primaryMemberEmail"
          type="email"
        />
        <Input
          label={getLocalizedText(appConfiguration, "streetAddressLabel", "Street Address")}
          name="primaryMemberStreetAddress1"
        />
        <Input
          label={getLocalizedText(appConfiguration, "streetAddress2Label", "Street Address 2 (optional)")}
          name="primaryMemberStreetAddress2"
        />
        <Input
          label={getLocalizedText(appConfiguration, "cityLabel", "City")}
          name="primaryMemberCity"
        />
        <Input
          label={getLocalizedText(appConfiguration, "stateLabel", "State/Province")}
          name="primaryMemberState"
        />
        <Input
          label={getLocalizedText(appConfiguration, "zipLabel", "ZIP/Postal Code")}
          name="primaryMemberZIP"
        />
        <Input
          label={getLocalizedText(appConfiguration, "countryLabel", "Country")}
          name="primaryMemberCountry"
        />
      </fieldset>
      <fieldset className="secondaryAdultMemberInformationSection">
        <TextContent
          appConfiguration={appConfiguration}
          contentKey="secondaryAdultMemberInformationSectionHeader"
          defaultValue="Secondary Adult Member Information"
          tag="legend"
        />
        <Input
          label={getLocalizedText(appConfiguration, "firstNameLabel", "First Name")}
          name="secondaryMemberFirstName"
        />
        <Input
          label={getLocalizedText(appConfiguration, "lastNameLabel", "Last Name")}
          name="secondaryMemberLastName"
        />
      </fieldset>
    </StepComponent>
  )
};

const MembershipInformation: Step = {
  Component,
  defaultLabel: "Information",
  isMembershipStep: true,
  isTicketStep: false,
  labelKey: "information",
  onSubmit,
  stepNumber: Steps.MembershipInformation,
};

export default MembershipInformation;