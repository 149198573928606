import classNames from "classnames";

import { AppConfiguration } from "../../fetchers/getAppConfiguration";
import TextContent from "../../components/TextContent";
import getLocalizedText from "../../formatters/getLocalizedText";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";
import { VoucherButtonModal } from "../../components/VoucherModal";
import { UseTicketData } from "../../hooks/useTicketData";
import { UseVoucherData } from "../../hooks/useVoucherData";
import { goToNextStep } from "..";

export interface VoucherOptionProps extends Pick<UseVoucherData, 
  "getVoucherData"
  | "isVoucherDataLoading"
  | "isVoucherDataValidating"
  | "voucherData"
  | "voucherDataError"
>, Pick<UseTicketData, "ticketCategories"> {
  appConfiguration: AppConfiguration | null | undefined;
}

export default function VoucherOption({
  appConfiguration,
  getVoucherData,
  isVoucherDataLoading,
  isVoucherDataValidating,
  ticketCategories,
  voucherData,
  voucherDataError,
}: VoucherOptionProps) {
  const { setFieldTouched, setFieldValue, values } = useFormikSchemaContext();
  
  if(!appConfiguration?.allow_guest_access || !appConfiguration?.allow_vouchers) {
    return "";
  }

  return (
    <li className={classNames([
      "loginOption",
      `loginOption--voucher`,
    ])}>
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="voucherTitle"
        defaultValue="Voucher"
        tag="h3"
      />
      <TextContent
        appConfiguration={appConfiguration}
        contentKey="voucherDescription"
        defaultValue="Have a voucher code? Enter it here to redeem."
        tag="p"
      />
      <VoucherButtonModal
        appConfiguration={appConfiguration}
        className="submitButton"
        onCloseButtonClick={async () => {
          await goToNextStep(values.stepNumber, values.stepsEnabled, setFieldValue, setFieldTouched);
        }}
        getVoucherData={getVoucherData}
        initialIsOpen={window.location.search.includes("voucher")}
        isVoucherDataLoading={isVoucherDataLoading}
        isVoucherDataValidating={isVoucherDataValidating}
        label={getLocalizedText(appConfiguration, "enterVouchers", "Enter Vouchers")}
        modalClassName="voucherModal"
        setFieldValue={setFieldValue}
        ticketCategories={ticketCategories}
        values={values}
        voucherData={voucherData}
        voucherDataError={voucherDataError}
      />
    </li>
  );
}