import { StepComponentProps } from "../Step";

import Carousel from "../../components/Carousel";
import TextContent from "../../components/TextContent";
import ToggleContainer from "../../components/ToggleContainer";
import SelectionButton from "./SelectionButton";
import getPriceFormatter from "../../formatters/getPriceFormatter";
import LoaderContainer from "../../components/LoaderContainer";
import getLocalizedDate from "../../formatters/getLocalizedDate";

export default function EventSelection({
  appConfiguration,
  isTicketDataLoading,
  isTicketDataValidating,
  setTicketEvents,
  summaryCategory,
  ticketDataError,
  ticketDate,
  ticketEvents,
  ticketSets,
}: StepComponentProps) {
  const locale = appConfiguration?.locale || "en-US";
  // Ticket times are already localized on the server.
  //const timeZone = appConfiguration?.source_time_zone || "GMT";

  const formatPrice = getPriceFormatter(locale, false);

  const timeFormatter = new Intl.DateTimeFormat(locale, {
    timeStyle: "short",
    //timeZone,
  });

  return (
    <ToggleContainer
      visible={!!ticketDate}
      render={() => (
        <>
          <TextContent
            appConfiguration={appConfiguration}
            contentKey="beforeEventSelection"
          />
          {ticketSets?.map(({
            description,
            image,
            label,
            quantity,
            summaries,
            tickets,
          }, ticketSetIndex) => (
            <div
              className="eventSelection__set"
              key={ticketSetIndex}
            >
              <TextContent
                appConfiguration={appConfiguration}
                contentKey="eventSelection__label"
                defaultValue={label || "Select Your Time"}
                tag="h3"
              />
              {image && <img
                className="eventSelection__image"
                src={image}
                alt="Event selection."
              />}
              {description && <div
                className="eventSelection__description"
                dangerouslySetInnerHTML={{ __html: description }}
              />}
              <LoaderContainer
                error={ticketDataError}
                isLoading={isTicketDataLoading}
                isValidating={isTicketDataValidating}
                render={() => (
                  <Carousel id={`event-selection-${ticketSetIndex}`}>
                    {tickets?.map(ticket => (
                      <SelectionButton
                        className="eventSelection__option"
                        disabled={!!ticketEvents.find(ticketEvent => {
                          if(ticketEvent.ticketSetIndex >= ticketSetIndex) {
                            return false;
                          }

                          const previousTicket = ticketSets[ticketEvent.ticketSetIndex].tickets.find(t => t.event_id === ticketEvent.eventID);

                          if(!previousTicket) {
                            return false;
                          }

                          const currentTicketEndTime = getLocalizedDate(ticket.end_time, appConfiguration?.source_time_zone);
                          const previousTicketStartTime = getLocalizedDate(previousTicket.start_time, appConfiguration?.source_time_zone);
                          
                          if(previousTicketStartTime >= currentTicketEndTime) {
                            return false;
                          }

                          const previousTicketEndTime = getLocalizedDate(previousTicket.end_time, appConfiguration?.source_time_zone);
                          const currentTicketStartTime = getLocalizedDate(ticket.start_time, appConfiguration?.source_time_zone);

                          if(previousTicketEndTime <= currentTicketStartTime) {
                            return false;
                          }

                          return true;
                        })}
                        format={formatPrice}
                        header={timeFormatter.format(new Date(ticket.start_time))}
                        key={ticket.id}
                        onClick={() => {
                          const selected = !!ticketEvents.find(ticketEvent => ticketEvent.eventID === ticket.event_id);

                          if(selected) {
                            setTicketEvents((oldTicketEvents) => {
                              return oldTicketEvents.filter(e => (
                                e.ticketSetIndex !== ticketSetIndex
                                || (
                                  e.eventID !== ticket.event_id
                                  || e.eventTemplateID !== ticket.template_id
                                  || e.ticketSetIndex !== ticketSetIndex
                                )
                              ));
                            });
                          } else {
                            setTicketEvents((oldTicketEvents) => {
                              if(!ticketSets[ticketSetIndex].comboTemplateID) {
                                quantity = 1;
                              }
                              
                              if(quantity) {
                                if(quantity === 1) {
                                  oldTicketEvents = oldTicketEvents.filter(e => (
                                    e.ticketSetIndex !== ticketSetIndex
                                    || (
                                      e.eventID === ticket.event_id
                                      && e.eventTemplateID === ticket.template_id
                                      && e.ticketSetIndex === ticketSetIndex
                                    )
                                  ));
                                } else {
                                  const count = oldTicketEvents.filter(e => (
                                    e.ticketSetIndex !== ticketSetIndex
                                    || (
                                      e.eventID === ticket.event_id
                                      && e.eventTemplateID === ticket.template_id
                                      && e.ticketSetIndex === ticketSetIndex
                                    )
                                  )).length;

                                  if(count >= quantity) {
                                    return oldTicketEvents;
                                  }
                                }
                              }

                              oldTicketEvents.push({
                                comboTemplateID: ticketSets[ticketSetIndex].comboTemplateID,
                                eventID: ticket.event_id,
                                eventTemplateID: ticket.template_id,
                                ticketSetIndex,
                              });

                              return [...oldTicketEvents];
                            });
                          }
                        }}
                        price={ticket.prices.find(price => price.applies_to === summaryCategory)?.price || ticket.prices.sort((a, b) => b.price - a.price)[0]?.price || 0}
                        seatsAvailable={ticket.seats_available}
                        seatsCapacity={ticket.seats_capacity}
                        selected={!!ticketEvents.find(ticketEvent => ticketEvent.eventID === ticket.event_id)}
                        showSummary={!summaries}
                        subHeader={summaries?.find(summary => summary.template_id === ticket.template_id)?.name}
                        summaryCategory={"Adult"}
                      />
                    ))}
                  </Carousel>
                )}
              />
              {/*(() => {
                const ticketSetEvents = ticketEvents.filter((t) => t.ticketSetIndex === ticketSetIndex);
                const currentEvent = ticketSetEvents.pop();
                const currentEventTemplateID = currentEvent?.eventTemplateID;
                const currentSummary = summaries?.find(summary => summary.template_id === currentEventTemplateID);

                return currentSummary && (
                  <div className="eventSelection__current">
                    <h3 className="eventSelection__current__name">{currentSummary.name}</h3>
                    <TextContent
                      appConfiguration={appConfiguration}
                      contentKey="defaultEventSelectionCurrentDescription"
                      defaultValue={currentSummary.description}
                    />
                  </div>
                )
              })()*/}
            </div>
          ))}
          <TextContent
            appConfiguration={appConfiguration}
            contentKey="afterEventSelection"
          />
        </>
      )}
    />
  );
}