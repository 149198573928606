import Steps from "./Steps";
import StepComponent, { Step, StepComponentProps } from "./Step";

import TextContent from "../components/TextContent";
import getPriceFormatter from "../formatters/getPriceFormatter";
import getLocalizedText from "../formatters/getLocalizedText";

const Component = (props: StepComponentProps) => {
  const {
    appConfiguration,
    checkoutOrder,
  } = props;

  const format = getPriceFormatter(appConfiguration?.locale);

  return (
    <StepComponent
      {...props}
      defaultHeaderLabel="Purchase Complete"
      showFooter={false}
      showHeader={true}
      showMemberJoinButton={true}
    >
      {checkoutOrder
        ? (
          <>
            <TextContent
              appConfiguration={appConfiguration}
              contentKey={"orderSummaryHeader"}
              defaultValue="Thank you for visiting. Your order has been confirmed."
              tag="h3"
            />
            <TextContent
              appConfiguration={appConfiguration}
              contentKey="beforeOrderSummary"
            />
            <table className="done__table">
              <tbody>
                <tr className="done__table__row done__table__row--orderNumber">
                  <th scope="row">{getLocalizedText(appConfiguration, "orderNumber", "Order Number")}</th>
                  <td>{checkoutOrder?.orderNumber}</td>
                </tr>
                <tr className="done__table__row done__table__row--confirmationEmail">
                  <th scope="row">{getLocalizedText(appConfiguration, "email", "Email")}</th>
                  <td>{checkoutOrder?.email}</td>
                </tr>
                <tr className="done__table__row done__table__row--total">
                <th scope="row">{getLocalizedText(appConfiguration, "total", "Total")}</th>
                  <td>{checkoutOrder?.paidAmount ? format(checkoutOrder.paidAmount) : "(None)"}</td>
                </tr>
              </tbody>
            </table>
            <TextContent
              appConfiguration={appConfiguration}
              contentKey="afterOrderSummary"
            />
          </>
        )
        : (
          <>
            <TextContent
              appConfiguration={appConfiguration}
              contentKey="noOrder"
              defaultValue="Your order has not been placed. Please go back and complete your order."
            />
          </>
        )
      }
    </StepComponent>
  )
};

const Done: Step = {
  Component,
  defaultLabel: "Done",
  isMembershipStep: true,
  isTicketStep: true,
  labelKey: "done",
  stepNumber: Steps.Done,
}

export default Done;