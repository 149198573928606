import Section from "./Section";

import { StepComponentProps } from "../Step";
import Input from "../../components/Input";
import getLocalizedText from "../../formatters/getLocalizedText";

export default function Target({
  appConfiguration,
}: StepComponentProps) {
  return (
    <Section
      appConfiguration={appConfiguration}
      defaultHeaderLabel={"Who is this Membership for?"}
      slug="target"
    >
      <Input
        id="membershipTarget-self"
        label={getLocalizedText(appConfiguration, "membershipTargetSelf", "This membership is for me.")}
        name="membershipTarget"
        type="radio"
        value="self"
      />
      <Input
        id="membershipTarget-gift"
        label={getLocalizedText(appConfiguration, "membershipTargetGift", "This membership is a gift membership (starts at purchase).")}
        name="membershipTarget"
        type="radio"
        value="gift"
      />
      {/*<Input
        id="membershipTarget-voucher"
        label={getLocalizedText(appConfiguration, "membershipTargetVoucher", "This membership is a membership voucher (starts when redeemed).")}
        name="membershipTarget"
        type="radio"
        value="voucher"
      />*/}
    </Section>
  )
}