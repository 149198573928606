import { useCallback, useRef, useState } from "react";

import { APIResponse, getAuthorizedAPIDataSharedArgs } from "../fetchers/getAuthorizedAPIData";
import createCart, { createCartArgs } from "../fetchers/createCart";
import getCartData, { Cart } from "../fetchers/getCartData";
import updateCart, { updateCartArgs } from "../fetchers/updateCart";
import deleteCart from "../fetchers/deleteCart";

export interface useCartData extends getAuthorizedAPIDataSharedArgs {

}

type CreateCartArgs = Omit<createCartArgs, "apiURL" | "authorizationToken" | "retrieveFullCart">;
type UpdateCartArgs = Omit<updateCartArgs, "apiURL" | "authorizationToken" | "retrieveFullCart">;
type CreateOrUpdateCartArgs = CreateCartArgs | UpdateCartArgs;

export type GetCartDataFunction = (id: string) => Promise<void>;
export type CreateCartFunction = (args: CreateCartArgs) => Promise<void>;
export type UpdateCartFunction = (args: UpdateCartArgs) => Promise<void>;
export type DeleteCartFunction = (id: string) => Promise<void>;
export type EmptyCartFuntion = () => Promise<void>;
export type CreateOrUpdateCartFunction = (args: CreateOrUpdateCartArgs) => Promise<void>;

export type UseCartData = {
  cartData: Cart | null | undefined;
  createCart: CreateCartFunction;
  createOrUpdateCart: CreateOrUpdateCartFunction;
  deleteCart: DeleteCartFunction;
  emptyCart: EmptyCartFuntion;
  getCart: GetCartDataFunction;
  updateCart: UpdateCartFunction;
  cartDataError: unknown;
  isCartDataLoading: boolean;
  isCartDataValidating: boolean;
};

export default function useCartData({
  apiURL,
  authorizationToken,
}: useCartData): UseCartData {
  const abortControllerRef = useRef<AbortController | null>();

  const [data, setData] = useState<Cart | null | undefined>(undefined);
  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(false);

  const bound: <T, U>(
    fetcher: (args: getAuthorizedAPIDataSharedArgs & T) => APIResponse<U>, args: T,
    onLoad: (result: U | null) => void
  ) => Promise<void> = useCallback(async (fetcher, args, onLoad) => {
    try {
      if(abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      setIsLoading(true);
      setIsValidating(true);
      
      abortControllerRef.current = new AbortController();

      const result = await fetcher({
        apiURL,
        authorizationToken,
        ...args
      });

      setError(null);
      onLoad(result);
    } catch(error) {
      setError(error);
    } finally {
      setIsLoading(false);
      setIsValidating(false);
      abortControllerRef.current = null;
    }
  }, [apiURL, authorizationToken]);

  const boundGetCart: GetCartDataFunction = useCallback((id) => bound(
    getCartData,
    { id },
    (data) => {
      if(data) {
        setData(data);
      }
    },
  ), [bound]);

  const boundCreateCart: CreateCartFunction = useCallback((args) => bound(
    createCart,
    {
      retrieveFullCart: true,
      verifyEntitlements: true,
      ...args
    },
    (data) => {
      if(data) {
        if(!data.items) {
          throw new Error("No cart data returned.");
        }

        setData(data);
      }
    },
  ), [bound]);

  const boundUpdateCart: UpdateCartFunction = useCallback((args) => bound(
    updateCart,
    {
      retrieveFullCart: true,
      verifyEntitlements: true,
      ...args
    },
    (data) => {
      if(data) {
        if(!data.items) {
          throw new Error("No cart data returned.");
        }
        
        setData(data);
      }
    },
  ), [bound]);
  
  const boundDeleteCart: DeleteCartFunction = useCallback((id) => bound(
    deleteCart,
    { id },
    () => setData(null),
  ), [bound]);

  const boundCreateOrUpdateCart: CreateOrUpdateCartFunction = useCallback((args) => bound<CreateOrUpdateCartArgs, Cart | null>(
    async (args) => {
      if(args.items?.length || args.comboItems?.length) {
        if("id" in args) {
          return updateCart(args);
        } else {
          return createCart(args);
        }
      } else {
        if("id" in args && args.id) {
          await deleteCart(args);
        }
        
        return null;
      }
    },
    {
      verifyEntitlements: true,
      ...args
    },
    (data) => {
      setData(data || null);
    },
  ), [bound]);

  const emptyCart = useCallback(async () => {
    if(data?.id) {
      await boundDeleteCart(data.id);
    }
  }, [boundDeleteCart, data?.id]);

  return {
    cartData: data,
    createCart: boundCreateCart,
    createOrUpdateCart: boundCreateOrUpdateCart,
    deleteCart: boundDeleteCart,
    emptyCart,
    getCart: boundGetCart,
    updateCart: boundUpdateCart,
    cartDataError: error,
    isCartDataLoading: isLoading,
    isCartDataValidating: isValidating,
  }
}