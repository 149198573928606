import { FormikHelpers } from "formik";
import { FC, PropsWithChildren, ReactNode } from "react";

import Footer from "./Footer";
import Header from "./Header";
import Steps from "./Steps";

import { UseAppConfiguration } from "../hooks/useAppConfiguration";
import { UseAuthorizationToken } from "../hooks/useAuthorizationToken";
import { UseAVSCountryData } from "../hooks/useAVSCountryData";
import { UseAVSStateData } from "../hooks/useAVSStateData";
import { UseCartData } from "../hooks/useCartData";
import { UseCORSCheckout } from "../hooks/useCORSCheckout";
import { UseDailyTicketData } from "../hooks/useDailyTicketData";
import { UseMemberData } from "../hooks/useMemberData";
import { UseMemberLevelData } from "../hooks/useMemberLevelData";
import { UseMembershipRestrictions } from "../hooks/useMembershipRestrictions";
import { UseScheduleCartUpdate } from "../hooks/useScheduleCartUpdate";
import { UseSchema } from "../hooks/useSchema";
import { UseTicketData } from "../hooks/useTicketData";
import { UseVoucherData } from "../hooks/useVoucherData";

import { Schema } from "../schema";

import LoaderContainer from "../components/LoaderContainer";

export type SharedStepProps = UseAppConfiguration 
  & UseAuthorizationToken
  & UseAVSCountryData
  & UseAVSStateData
  & UseCartData
  & UseCORSCheckout
  & UseDailyTicketData
  & UseMemberData
  & UseMemberLevelData
  & UseMembershipRestrictions
  & UseSchema
  & UseTicketData
  & UseVoucherData;

export type StepComponentProps = PropsWithChildren<SharedStepProps & UseScheduleCartUpdate & {
  error?: unknown;
  defaultHeaderLabel?: string;
  beforeHeader?: ReactNode;
  afterHeader?:  ReactNode;
  beforeFooter?: ReactNode;
  afterFooter?: ReactNode;
  beforeSubmitButton?: ReactNode;
  afterSubmitButton?: ReactNode;
  headerLabelKey?: string;
  isLoading?: boolean;
  isValidating?: boolean;
  onSubmitButtonClick?: () => void;
  showFooter?: boolean;
  showHeader?: boolean;
  showSteps?: boolean;
  showDateSelectButton?: boolean;
  showMemberJoinButton?: boolean;
  submitButtonLabel?: string;
}>;

export interface StepSubmitHandlerArgs extends SharedStepProps {
  helpers: FormikHelpers<Schema>;
  values: Schema;
}

export type StepSubmitHandler = (args: StepSubmitHandlerArgs) => Promise<void>;

export interface Step {
  Component: FC<StepComponentProps>;
  defaultLabel: string;
  excludeFromHeader?: boolean;
  isMembershipStep: boolean;
  isTicketStep: boolean;
  labelKey: string;
  liveCart?: boolean;
  onSubmit?: StepSubmitHandler;
  stepNumber: Steps;
}

export default function Step(props: StepComponentProps) {
  const {
    children,
    error,
    isLoading,
    isValidating,
    showFooter = true,
    showHeader = true,
  } = props;

  return (
    <LoaderContainer
      appConfiguration={props.appConfiguration}
      error={error ?? (props.authorizationTokenError || props.appConfigurationError)}
      isLoading={isLoading ?? (props.isAuthorizationTokenLoading || props.isAppConfigurationLoading)}
      isValidating={isValidating ?? (props.isAuthorizationTokenValidating || props.isAppConfigurationValidating)}
    >
      {showHeader && <Header {...props} />}
      {children}
      {showFooter && <Footer {...props} />}
    </LoaderContainer>
  );
}