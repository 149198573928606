import classNames from "classnames";

import steps, { goToStep, stepIsAfter, Steps } from "..";
import { StepComponentProps } from "../Step";

import getLocalizedText from "../../formatters/getLocalizedText";
import useFormikSchemaContext from "../../hooks/useFormikSchemaContext";

export default function StepsList({
  appConfiguration,
  meta,
  isCheckoutLoading,
  isCheckoutValidating,
}: StepComponentProps) {
  const { setFieldTouched, setFieldValue, values } = useFormikSchemaContext();
  const { stepNumber, stepsEnabled } = values;

  const stepsActive = steps.reduce((acc, step) => {
    acc[step.stepNumber] = (
      !stepIsAfter(step.stepNumber, stepNumber)
      && stepsEnabled[step.stepNumber]
    );

    return acc;
  }, {} as Record<Steps, boolean>);

  const currentStepIndex = steps.findIndex(step => step.stepNumber === stepNumber);
  let activeStepIndex = currentStepIndex;

  while(steps[activeStepIndex] && steps[activeStepIndex].excludeFromHeader) {
    activeStepIndex--;
  }

  const activeStepNumber = steps[activeStepIndex]?.stepNumber;

  return (
    <ol className="header__list">
      {steps
        .filter(step => !step.excludeFromHeader && stepsEnabled[step.stepNumber])
        .map(step => (
          <li
            className={classNames({
              "header__list__item": true,
              "header__list__item--current": step.stepNumber === activeStepNumber,
              "header__list__item--disabled": !stepsActive[step.stepNumber],
              "header__list__item--enabled": stepsActive[step.stepNumber],
            })}
            key={step.stepNumber}
          >
            <button
              className="header__list__item__button"
              disabled={!stepsActive[step.stepNumber] || isCheckoutLoading || isCheckoutValidating}
              onClick={(e) => {
                e.preventDefault();

                if(stepsActive[step.stepNumber]) {
                  goToStep(step, stepsEnabled, setFieldValue, setFieldTouched, meta);
                }
              }}
              type="button"
            >{getLocalizedText(appConfiguration, step.labelKey, step.defaultLabel)}</button>
          </li>
        )
      )}
    </ol>
  );
}