import StepComponent, { Step, StepSubmitHandler, StepComponentProps } from "./Step";
import Steps from "./Steps";

import TicketConfirmation from "../components/TicketConfirmation";
import { goToNextStep } from ".";

const onSubmit: StepSubmitHandler = async ({
  helpers,
  meta,
  values,
}) => {
  await helpers.setFieldValue("ticketsConfirmed", "1");
  await goToNextStep(values.stepNumber, values.stepsEnabled, helpers.setFieldValue, helpers.setFieldTouched, meta);
};

const Component = (props: StepComponentProps) => {
  return (
    <StepComponent {...props}>
      <TicketConfirmation {...props} showTotal={false} />
    </StepComponent>
  );
}

const step: Step = {
  Component,
  defaultLabel: "Confirm Tickets",
  excludeFromHeader: true,
  isMembershipStep: false,
  isTicketStep: true,
  labelKey: "confirmTickets",
  onSubmit,
  stepNumber: Steps.TicketConfirmation,
}

export default step;