import cardValidator from "card-validator";
import { AnyObject, array, boolean, BooleanSchema, InferType, object, number, Schema as YupSchema, SchemaRefDescription, string, SchemaObjectDescription } from "yup";

import steps, { stepIsOnOrAfter, Steps } from "../steps";
import { LoginType, loginTypes } from "../steps/Login";
import { CartDonationDonorRecognition } from "../fetchers/getCartData";

export const getSchema = () => object().shape({

  // Global

  stepNumber: number().default(steps[0].stepNumber),
  stepsEnabled: object()
    .shape(steps.reduce((acc, step) => {
      acc[step.stepNumber] = boolean().default(false);

      return acc;
    }, {} as Record<Steps, BooleanSchema<boolean | undefined, AnyObject, false, "d">>))
    .default({}),

  // Login

  loginType: string()
    .label("Log-in type")
    .meta({ stepNumber: Steps.Login })
    .default(LoginType.Guest)
    .oneOf(loginTypes),
  membershipNumber: string()
    .label("Membership number")
    .meta({ stepNumber: Steps.Login })
    .matches(/^[A-Z0-9-]*$/, "${label} should contain only uppercase letters, numbers, and dashes")
    .when("loginType", {
      is: LoginType.Member,
      then: (schema: YupSchema) => schema.required()
    })
    .default(""),
  membershipLastName: string()
    .label("Last name")
    .meta({ stepNumber: Steps.Login })
    .when("loginType", {
      is: LoginType.Member,
      then: (schema: YupSchema) => schema.required()
    })
    .default(""),
  residentZIPCode: string()
    .label("ZIP/postal code")
    .meta({ stepNumber: Steps.Login })
    .when("loginType", {
      is: LoginType.Resident,
      then: (schema: YupSchema) => schema
        .required()
        .matches(/^[0-9-]+$/, "Not a valid ZIP code.")
    })
    .default(""),
  isResident: boolean()
    .label("Is resident")
    .meta({ stepNumber: Steps.Login })
    .default(false),

  // Membership Review

  membershipLevelSlug: string()
    .label("Membership level")
    .meta({ stepNumber: Steps.MembershipReview })
    .when("stepsEnabled", {
      is: (stepsEnabled: Record<number, boolean> | undefined) => stepsEnabled && stepsEnabled[Steps.MembershipReview],
      then: (schema: YupSchema) => schema.required()
    })
    .default(""),
  membershipLevelID: string()
    .label("Membership level info")
    .meta({ stepNumber: Steps.MembershipReview })
    .when("stepsEnabled", {
      is: (stepsEnabled: Record<number, boolean> | undefined) => stepsEnabled && stepsEnabled[Steps.MembershipReview],
      then: (schema: YupSchema) => schema.required()
    })
    .default(""),
  membershipTarget: string()
    .label("Membership target")
    .meta({ stepNumber: Steps.MembershipReview })
    .when("stepsEnabled", {
      is: (stepsEnabled: Record<number, boolean> | undefined) => stepsEnabled && stepsEnabled[Steps.MembershipReview],
      then: (schema: YupSchema) => schema.required()
    })
    .default(""),

  // Membership Information

  primaryMemberFirstName: string()
    .label("First name")
    .meta({ stepNumber: Steps.MembershipInformation })
    .when(["stepsEnabled", "stepNumber"], {
      is: (stepsEnabled: Record<number, boolean> | undefined, stepNumber: number) => (
        stepsEnabled 
        && stepsEnabled[Steps.MembershipInformation]
        && stepIsOnOrAfter(stepNumber, Steps.MembershipInformation)
      ),
      then: (schema: YupSchema) => schema.required()
    })
    .default(""),
  primaryMemberLastName: string()
    .label("Last name")
    .meta({ stepNumber: Steps.MembershipInformation })
    .when(["stepsEnabled", "stepNumber"], {
      is: (stepsEnabled: Record<number, boolean> | undefined, stepNumber: number) => (
        stepsEnabled 
        && stepsEnabled[Steps.MembershipInformation]
        && stepIsOnOrAfter(stepNumber, Steps.MembershipInformation)
      ),
      then: (schema: YupSchema) => schema.required()
    })
    .default(""),
  primaryMemberEmail: string()
    .label("Email")
    .meta({ stepNumber: Steps.MembershipInformation })
    .when(["stepsEnabled", "stepNumber"], {
      is: (stepsEnabled: Record<number, boolean> | undefined, stepNumber: number) => (
        stepsEnabled
        && stepsEnabled[Steps.MembershipInformation]
        && stepIsOnOrAfter(stepNumber, Steps.MembershipInformation)
      ),
      then: (schema: YupSchema) => schema
        .required()
        .email()
    })
    .default(""),
  primaryMemberPhoneNumber: string()
    .label("Phone number")
    .meta({ stepNumber: Steps.MembershipInformation })
    .when(["stepsEnabled", "stepNumber"], {
      is: (stepsEnabled: Record<number, boolean> | undefined, stepNumber: number) => (
        stepsEnabled
        && stepsEnabled[Steps.MembershipInformation]
        && stepIsOnOrAfter(stepNumber, Steps.MembershipInformation)
      ),
      then: (schema: YupSchema) => schema.required()
    })
    .default(""),
  primaryMemberStreetAddress1: string()
    .label("Street address")
    .meta({ stepNumber: Steps.MembershipInformation })
    .when(["stepsEnabled", "stepNumber"], {
      is: (stepsEnabled: Record<number, boolean> | undefined, stepNumber: number) => (
        stepsEnabled
        && stepsEnabled[Steps.MembershipInformation]
        && stepIsOnOrAfter(stepNumber, Steps.MembershipInformation)
      ),
      then: (schema: YupSchema) => schema.required()
    })
    .default(""),
  primaryMemberStreetAddress2: string()
    .label("Street address")
    .meta({ stepNumber: Steps.MembershipInformation })
    .default(""),
  primaryMemberCity: string()
    .label("City")
    .meta({ stepNumber: Steps.MembershipInformation })
    .when(["stepsEnabled", "stepNumber"], {
      is: (stepsEnabled: Record<number, boolean> | undefined, stepNumber: number) => (
        stepsEnabled
        && stepsEnabled[Steps.MembershipInformation]
        && stepIsOnOrAfter(stepNumber, Steps.MembershipInformation)
      ),
      then: (schema: YupSchema) => schema.required()
    })
    .default(""),
  primaryMemberState: string()
    .label("State")
    .meta({ stepNumber: Steps.MembershipInformation })
    .when(["stepsEnabled", "stepNumber"], {
      is: (stepsEnabled: Record<number, boolean> | undefined, stepNumber: number) => (
        stepsEnabled
        && stepsEnabled[Steps.MembershipInformation]
        && stepIsOnOrAfter(stepNumber, Steps.MembershipInformation)
      ),
      then: (schema: YupSchema) => schema.required()
    })
    .default(""),
  primaryMemberZIP: string()
    .label("ZIP/postal code")
    .meta({ stepNumber: Steps.MembershipInformation })
    .when(["stepsEnabled", "stepNumber"], {
      is: (stepsEnabled: Record<number, boolean> | undefined, stepNumber: number) => (
        stepsEnabled
        && stepsEnabled[Steps.MembershipInformation]
        && stepIsOnOrAfter(stepNumber, Steps.MembershipInformation)
      ),
      then: (schema: YupSchema) => schema
        .required()
        .matches(/^[0-9-]+$/, "Not a valid ZIP code.")
    })
    .default(""),
  primaryMemberCountry: string()
    .label("City")
    .meta({ stepNumber: Steps.MembershipInformation })
    .when(["stepsEnabled", "stepNumber"], {
      is: (stepsEnabled: Record<number, boolean> | undefined, stepNumber: number) => (
        stepsEnabled
        && stepsEnabled[Steps.MembershipInformation]
        && stepIsOnOrAfter(stepNumber, Steps.MembershipInformation)
      ),
      then: (schema: YupSchema) => schema.required()
    })
    .default("United States of America"),
  secondaryMemberFirstName: string()
    .label("First name")
    .meta({ stepNumber: Steps.MembershipInformation })
    .default(""),
  secondaryMemberLastName: string()
    .label("Last name")
    .meta({ stepNumber: Steps.MembershipInformation })
    .default(""),
  
  // Tickets
  
  tickets: array()
    .label("Tickets")
    .meta({ stepNumber: Steps.Tickets })
    .of(object().shape({
      comboTemplateID: string(),
      eventID: string().required(),
      eventTemplateID: string().required(),
      isComboTicket: boolean(),
      quantity: number().required(),
      ticketingTypeID: string().required(),
    }))
    .compact((ticket) => !ticket.quantity)
    .when(["stepNumber", "stepsEnabled"], {
      is: (stepNumber: Steps, stepsEnabled: Record<number, boolean> | undefined) => (
        stepsEnabled
        && stepsEnabled[Steps.Tickets] 
        && stepIsOnOrAfter(stepNumber, Steps.Tickets)
      ),
      then: (schema) => schema
        .required()
        .min(1),
    })
    .default([]),
  ticketsConfirmed: string()
    .label("Tickets confirmed")
    .meta({ stepNumber: Steps.Tickets })
    .default(""),
  voucherCodes: array()
    .label("Voucher codes")
    .meta({ stepNumber: Steps.Tickets })
    .of(string())
    .default([""]),

  // Donation

  donationID: string()
    .label("Selected donation")
    .meta({ stepNumber: Steps.Donation })
    .default(""),
  donationType: string()
    .label("Donation type")
    .meta({ stepNumber: Steps.Donation })
    .default(""),
  donorRecognitionType: string()
    .label("Donor recognition")
    .meta({ stepNumber: Steps.Donation })
    .default("self")
    .when(["stepNumber", "donationID"], {
      is: (stepNumber: number, donationID: string) => (
        donationID
        && stepIsOnOrAfter(stepNumber, Steps.Donation)
      ),
      then: (schema) => schema.required(),
    }),
  donationNotes: string()
    .label("Donation notes")
    .meta({ stepNumber: Steps.Donation })
    .default("")
    .when(["stepNumber", "donorRecognitionType"], {
      is: (stepNumber: number, donorRecognitionType: CartDonationDonorRecognition) => (
        donorRecognitionType === "organization"
        && stepIsOnOrAfter(stepNumber, Steps.Donation)
      ),
      then: (schema) => schema.required("${label} are required when donating to an organization"),
    }),
  customDonationAmount: string()
    .label("Donation amount")
    .meta({ stepNumber: Steps.Donation })
    .default("")
    .transform((value) => value?.toString().trim())
    .test("isCurrency", "${label} must be a monetary value.", (value) => {
      return !value || /\$?[0-9]+(\.[0-9]{2})?$/.test(value);
    })
    .when(["stepNumber", "donationType"], {
      is: (stepNumber: number, donationType: "fixedDonation" | "donation") => (
        donationType === "donation"
        && stepIsOnOrAfter(stepNumber, Steps.Donation)
      ),
      then: (schema) => schema.required(),
    }),

  // Add-ons

  addOns: array(object()
    .shape({
      inventoryId: string()
        .required(),
      quantity: number()
        .required()
        .min(1),
    })
  )
    .label("Add-ons")
    .meta({ stepNumber: Steps.Addons })
    .default([])
    .compact((value) => !value || value.quantity < 1),

  // Billing

  paymentRequired: boolean()
    .label("Payment required")
    .meta({ stepNumber: Steps.Billing })
    .default(false),
  firstName: string()
    .label("First name")
    .meta({ stepNumber: Steps.Billing })
    .default("")
    .when("stepNumber", {
      is: (stepNumber: number) => stepIsOnOrAfter(stepNumber, Steps.Billing),
      then: (schema) => schema.required(),
    }),
  lastName: string()
    .label("Last name")
    .meta({ stepNumber: Steps.Billing })
    .default("")
    .when("stepNumber", {
      is: (stepNumber: number) => stepIsOnOrAfter(stepNumber, Steps.Billing),
      then: (schema) => schema.required(),
    }),
  cardNumber: string()
    .label("Card number")
    .meta({ stepNumber: Steps.Billing })
    .default("")
    .when(["stepNumber", "paymentRequired"], {
      is: (stepNumber: number, paymentRequired: boolean) => paymentRequired && stepIsOnOrAfter(stepNumber, Steps.Billing),
      then: (schema) => schema
        .required()
        .test("isValidCardNumber", "${label} is invalid.", (value) => cardValidator.number(value).isValid),
    }),
  securityCode: string()
    .label("Security code (CVC)")
    .meta({ stepNumber: Steps.Billing })
    .default("")
    .when(["stepNumber", "paymentRequired"], {
      is: (stepNumber: number, paymentRequired: boolean) => paymentRequired && stepIsOnOrAfter(stepNumber, Steps.Billing),
      then: (schema) => schema
        .required()
        .test("isValidSecurityCode", "${label} is invalid.", (value) => cardValidator.cvv(value).isValid),
    }),
  expirationMonth: string()
    .label("Expiration month")
    .meta({ stepNumber: Steps.Billing })
    .default("")
    .when(["stepNumber", "paymentRequired"], {
      is: (stepNumber: number, paymentRequired: boolean) => paymentRequired && stepIsOnOrAfter(stepNumber, Steps.Billing),
      then: (schema) => schema
        .required()
        .test("isValidExpirationMonth", "${label} is invalid.", (value) => cardValidator.expirationMonth(value).isValid),
    }),
  expirationYear: string()
    .label("Expiration year")
    .meta({ stepNumber: Steps.Billing })
    .default("")
    .when(["stepNumber", "paymentRequired"], {
      is: (stepNumber: number, paymentRequired: boolean) => paymentRequired && stepIsOnOrAfter(stepNumber, Steps.Billing),
      then: (schema) => schema
        .required()
        .test("isValidExpirationYear", "${label} is invalid.", (value) => cardValidator.expirationYear(value).isValid),
    }),
  streetAddress1: string()
    .label("Street address")
    .meta({ stepNumber: Steps.Billing })
    .default("")
    .when(["stepNumber", "paymentRequired"], {
      is: (stepNumber: number, paymentRequired: boolean) => paymentRequired && stepIsOnOrAfter(stepNumber, Steps.Billing),
      then: (schema) => schema.required(),
    }),
  streetAddress2: string()
    .label("Street address 2")
    .meta({ stepNumber: Steps.Billing })
    .default(""),
  city: string()
    .label("City")
    .meta({ stepNumber: Steps.Billing })
    .default("")
    .when(["stepNumber", "paymentRequired"], {
      is: (stepNumber: number, paymentRequired: boolean) => paymentRequired && stepIsOnOrAfter(stepNumber, Steps.Billing),
      then: (schema) => schema.required()
    }),
  state: string()
    .label("State/province")
    .meta({ stepNumber: Steps.Billing })
    .default("")
    .when(["stepNumber", "paymentRequired"], {
      is: (stepNumber: number, paymentRequired: boolean) => paymentRequired && stepIsOnOrAfter(stepNumber, Steps.Billing),
      then: (schema) => schema.required()
    }),
  zip: string()
    .label("ZIP/postal code")
    .meta({ stepNumber: Steps.Billing })
    .default("")
    .when(["stepNumber", "paymentRequired"], {
      is: (stepNumber: number, paymentRequired: boolean) => paymentRequired && stepIsOnOrAfter(stepNumber, Steps.Billing),
      then: (schema) => schema
        .required()
        .matches(/^[0-9-]+$/, "Not a valid ZIP code.")
    }),
  country: string()
    .label("Country")
    .meta({ stepNumber: Steps.Billing })
    .default("United States")
    .when(["stepNumber", "paymentRequired"], {
      is: (stepNumber: number, paymentRequired: boolean) => paymentRequired && stepIsOnOrAfter(stepNumber, Steps.Billing),
      then: (schema) => schema.required()
    }),
  phoneNumber: string()
    .label("Phone number")
    .meta({ stepNumber: Steps.Billing })
    .default("")
    .when(["stepNumber", "paymentRequired"], {
      is: (stepNumber: number, paymentRequired: boolean) => paymentRequired && stepIsOnOrAfter(stepNumber, Steps.Billing),
      then: (schema) => schema.required()
    }),
  email: string()
    .label("Email address")
    .meta({ stepNumber: Steps.Billing })
    .default("")
    .when("stepNumber", {
      is: (stepNumber: number) => stepIsOnOrAfter(stepNumber, Steps.Billing),
      then: (schema) => schema
        .required()
        .email()
    }),

  // Review

  hasPromoCode: array()
    .of(string())
    .label("Has promo code")
    .meta({ stepNumber: Steps.Review })
    .default([]),
  promoCode: string()
    .label("Promo code")
    .meta({ stepNumber: Steps.Review })
    .default("")
    .when(["stepNumber", "hasPromoCode"], {
      is: (stepNumber: number, hasPromoCode: string) => hasPromoCode?.length && stepIsOnOrAfter(stepNumber, Steps.Review),
      then: (schema) => schema
        .required()
    }),
  recaptchaV2Token: string()
    .label("RECAPTCHA V2")
    .meta({ stepNumber: Steps.Review })
    .default("NOT NEEDED")
    .when("stepNumber", {
      is: (stepNumber: number) => stepIsOnOrAfter(stepNumber, Steps.Review),
      then: (schema) => schema
        .required()
    }),
  recaptchaV2Valid: boolean()
    .label("RECAPTCHA V2")
    .meta({ stepNumber: Steps.Review })
    .default(true)
    .when("stepNumber", {
      is: (stepNumber: number) => stepIsOnOrAfter(stepNumber, Steps.Review),
      then: (schema) => schema
        .required()
        .test(recaptchaV2Valid => !!recaptchaV2Valid)
    }),
  recaptchaV3Token: string()
    .label("RECAPTCHA V3")
    .meta({ stepNumber: Steps.Review })
    .default("NOT NEEDED")
    .when("stepNumber", {
      is: (stepNumber: number) => stepIsOnOrAfter(stepNumber, Steps.Review),
      then: (schema) => schema
        .required()
    }),
  recaptchaV3Valid: boolean()
    .label("RECAPTCHA V3")
    .meta({ stepNumber: Steps.Review })
    .default(true)
    .when("stepNumber", {
      is: (stepNumber: number) => stepIsOnOrAfter(stepNumber, Steps.Review),
      then: (schema) => schema
        .required()
        .test(recaptchaV2Valid => !!recaptchaV2Valid)
    }),
});

export type Schema = InferType<ReturnType<typeof getSchema>>;

export type ExpandedSchemaRefDescription = SchemaRefDescription & {
  meta?: {
    stepNumber?: Steps
  }
};

export type ExpandedSchemaObjectDescription = SchemaObjectDescription & {
  fields: Record<string, ExpandedSchemaRefDescription>
};
